import React from 'react'
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'
import { PreviewProvider } from '@context/previewContext'
import '@contentful/live-preview/style.css'

// Wrap with providers that are NOT dependent on the route/location
export const wrapRootElement = ({ element, props }) => {
  return (
    <ContentfulLivePreviewProvider
      locale="en-US"
      debugMode
      enableInspectorMode
      enableLiveUpdates
    >
      <PreviewProvider>{element}</PreviewProvider>
    </ContentfulLivePreviewProvider>
  )
}
